/* eslint no-console:0 */
require.context('./images', true);
require.context('./imgs', true);

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'antd/dist/antd.less';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles/application.scss';

import $ from 'jquery';
import onmount from 'onmount';
import Turbolinks from 'turbolinks';

Turbolinks.start();

document.addEventListener('turbolinks:before-render', () => {
  Turbolinks.clearCache();
});

$(document).on('ready page:change turbolinks:load', () => {
  onmount();
});

$(window).on('resize touchstart', () => onmount());
$('body').on('touchstart', () => onmount());

$(document).on('turbolinks:before-cache', () => {
  onmount.teardown();
});
